// routes
import Home from './routes/Home';
import Login from './routes/Login';
import Register from './routes/Register';
import Code from './routes/Code';


const routes = [
  {
    path: '/',
    exact: true,
    Component: Home
  },
  {
    path: '/login',
    Component: Login
  },
  {
    path: '/register',
    Component: Register
  },
  {
    path: '/code/:id?',
    Component: Code
  },
];

export default routes;
