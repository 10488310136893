import * as React from "react";
import { useCallback } from "react";
import Logo from "./Logo";
import Dialog from "./Dialog";
import { useSelector, useDispatch } from "react-redux";
import { BASE_URL } from "../config";
import { Link } from "react-router-dom";

const AppLayout = ({ children }) => {
    const isDialogOpen = useSelector((state) => state.isDialogOpen);
    const isPreviewDialog = useSelector((state) => state.isPreviewDialog);
    const dispatch = useDispatch();
    const token = localStorage.getItem('token');
    const email = localStorage.getItem('email');

    if (!token || token === 'undefined') {
        window.location.href = '/login';
    }
    const prompts = useSelector((state) => state.prompts);
    const updatePrompts = useCallback((prompts) => {
        dispatch({ type: 'PROMPTS', payload: prompts });
    }, [dispatch]);
    
    const handleDialogOpen = () => {
        dispatch({ type: 'OPEN_DIALOG' });
    };

    const handleDialogClose = (input) => {
        dispatch({ type: 'CLOSE_DIALOG' });
        generateCode(input);
    };

    const handleLogout = () => {
        fetch(`${BASE_URL}/logout`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
        }).then((res) => {
            localStorage.removeItem('token');
            localStorage.removeItem('email');
            window.location.href = '/login';
        }).catch((error) => {
            console.error('Error:', error);
        });
    }

    const getPrompts = useCallback(() => {
        // send a GET request to the server http://localhost:5000/prompts
        fetch(`${BASE_URL}/prompts`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            },
        }).then((res) => {
            if (res.status === 401) {
                localStorage.removeItem('token');
                localStorage.removeItem('email');
                window.location.href = '/login';
            }
            return res.json()
        }).then((data) => {
                if (data.error) {
                    alert(data.error);
                } else {
                    updatePrompts(data);
                }
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }, [updatePrompts]);


    const generateCode = (input) => {
        // send a POST request to the server http://localhost:5000/generate_code
        fetch(`${BASE_URL}/generate_code`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({ "prompt": input }),
        }).then((res) => {
            if (res.status === 401) {
                localStorage.removeItem('token');
                localStorage.removeItem('email');
                window.location.href = '/login';
            }
            return res.json()
        }).then((data) => {
                if (data.error) {
                    alert(data.error);
                } else {
                    // add the prompts to the list
                    getPrompts();
                }
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }

    React.useEffect(() => {
        getPrompts();
    }, [getPrompts]);

    return (
        <div className="min-h-screen flex flex-col">
            <header className="p-4 flex justify-between items-center bg-everglade-800 text-white">
                <div>
                    <h1 className="text-xl"><Logo />  <small>v0.1.0 <sup>(experimental)</sup></small></h1>
                    <small>Created by Vikrant Singh Chauhan</small>
                </div>
                <nav>
                    <ul className="flex space-x-4">
                        <li>
                            <small className="block px-4 py-2" >{email}</small>
                        </li>
                        <li>
                            <button className="block px-4 py-2 rounded bg-everglade-700 hover:bg-everglade-500" onClick={handleLogout}>Logout</button>
                        </li>
                    </ul>
                </nav>
            </header>
            <div className="flex flex-1">
                <aside className="w-64 bg-everglade-100 p-4">
                    <button className="block px-4 py-2 mb-4 w-full rounded bg-everglade-700 text-white hover:bg-everglade-500" onClick={handleDialogOpen}>Create New</button>
                    <nav>
                        <ul style={{overflow: "scroll", maxHeight: "70vh"}}>
                            {prompts.length > 0 && prompts.map((prompt) => (
                                <li key={prompt.prompt_id}>
                                    <Link to={`/code/${prompt.prompt_id}`} className="block p-2 bg-white text-gray-900 border border-gray-200 rounded hover:bg-gray-100">{prompt.program_name}</Link>
                                </li>
                            ))}
                        </ul>
                    </nav>
                </aside>
                <main className="flex-1 flex flex-col p-4 bg-everglade-50">
                    {children}
                </main>
            {isDialogOpen && <Dialog isOpen={isDialogOpen} onClose={handleDialogClose} isPreview={isPreviewDialog} />}
        </div>
    </div>
    );
};

export default AppLayout;