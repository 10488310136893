import React, { useState } from 'react';
import { useSelector } from 'react-redux';

const Dialog = ({ isOpen, onClose, isPreview }) => {
    const [input, setInput] = useState('');
    const prompt = useSelector((state) => state.openedPrompt);
    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-everglade-800 bg-opacity-50">
            <div className="bg-white p-6 rounded-lg shadow-lg w-3/4">
                <h2 className="text-xl font-semibold mb-4">Write a detailed description of what you want to do</h2>
                <textarea
                    className="w-full p-2 border border-gray-300 rounded-md"
                    placeholder={`Create a calculator program that can add, subtract, multiply, and divide two numbers.`}
                    rows={10}
                    disabled={isPreview}
                    value={isPreview ? prompt.response : input}
                    onChange={(e) => setInput(e.target.value)}
                ></textarea>
                <div className="mt-4 flex justify-end">
                    {
                    !isPreview && <button
                        className="bg-everglade-500 text-white px-4 py-2 rounded-md mr-2"
                        onClick={() => {
                            onClose(input);
                            setInput('');
                        }}
                    >
                        Submit
                    </button>
                    }
                    <button
                        className="bg-everglade-500 text-white px-4 py-2 rounded-md"
                        onClick={() => {
                            onClose(null);
                            setInput('');
                        }}
                    >
                        {isPreview ? 'Close' : 'Cancel'}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Dialog;