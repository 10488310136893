import { configureStore } from '@reduxjs/toolkit';

const initialState = {
  isDialogOpen: false,
  isPreviewDialog: false,
  prompts: [],
  openedPrompt:  {
      'prompt_id': 0,
      'prompt_description': 'No prompt opened',
      'response': 'No prompt opened',
      'program_name': 'No program opened',
      'program_status': 'Not started',
      'program_url': 'data:text/html,<pre>No program opened</pre>',
  },
};

function rootReducer(state = initialState, action) {
  switch (action.type) {
    case 'OPEN_DIALOG':
      return {
        ...state,
        isDialogOpen: true
      };
    case 'OPEN_DIALOG_PREVIEW':
      return {
        ...state,
        isDialogOpen: true,
        isPreviewDialog: true
      };
    case 'CLOSE_DIALOG':
      return {
        ...state,
        isDialogOpen: false,
        isPreviewDialog: false
      };
    case 'PROMPTS':
      return {
        ...state,
        prompts: action.payload
      };
    case 'OPENED_PROMPT':
      return {
        ...state,
        openedPrompt: action.payload
      };
    default:
      return state;
  }
}

const store = configureStore({ reducer: rootReducer });

export default store;
