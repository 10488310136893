import * as React from "react"
import AppLayout from "../components/AppLayout"
import {
    FaTrash,
    FaEye,
} from 'react-icons/fa';
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { BASE_URL } from "../config";

const Code = () => {
    const prompt_id = useParams().id;
    const token = localStorage.getItem('token');
    if (!token) {
        window.location.href = '/login';
    }
    
    const dispatch = useDispatch();   
    const opened_prompt = useSelector((state) => state.openedPrompt);
    const prompts = useSelector((state) => state.prompts);

    const iframe_srcdoc = `
    <!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Made with Sasha</title>
    <style>
        body, html {
            height: 100%;
            margin: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #f0f0f0;
        }
        #output {
            font-size: 2em;
        }
    </style>
</head>
<body>
    <div id="output">Loading...</div>
    <script src="${window.location.origin}/wasm_exec.js"></script>
    <script>
        async function loadWasm() {
            const go = new Go();

            try {
                    const url = '${opened_prompt.program_url}'
                    const response = await fetch(url);
                    const buffer = await response.arrayBuffer();
                    const wasmModule = await WebAssembly.instantiate(buffer, go.importObject);
                    const originalLog = console.log;
                    console.log = function (...args) {
                        document.getElementById('output').innerText += args.join(' ') + '\\n'; 
                        originalLog.apply(console, args);
                    };
                    
                    document.getElementById('output').innerText = '';
                    go.run(wasmModule.instance);
                    console.log = originalLog;
            } catch (err) {
                console.error("Error loading or running WebAssembly: ", err);
            }
        }
        
        loadWasm();
    </script>
</body>
</html>
    `
    
    const handleDialogOpen = () => {
        dispatch({ type: 'OPEN_DIALOG_PREVIEW' });
    };
    
    const [title, setTitle] = React.useState(opened_prompt.program_name);
    const [isEditing, setIsEditing] = React.useState(false);

    const openPrompt = React.useCallback((id) => {
        prompts.map((prompt) => {
            if (prompt.prompt_id === parseInt(id)) {
                setTitle("Loading...");

                fetch(`${BASE_URL}/program/${id}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    },
                }).then((res) => {
                    if (res.status === 401) {
                        localStorage.removeItem('token');
                        localStorage.removeItem('email');
                        window.location.href = '/login';
                    }
                    return res.json();
                })
                .then((data) => {
                    if (data.error) {
                        dispatch({ type: 'OPENED_PROMPT', payload: {
                            'prompt_id': id,
                            'prompt_description': 'Invalid prompt',
                            'response': 'Invalid prompt',
                            'program_name': 'Invalid prompt',
                            'program_status': 'Failed',
                            'program_url': 'data:text/html,<pre>Invalid prompt, please delete this one and try again.</pre>',
                        }});
                    } else {
                        dispatch({ type: 'OPENED_PROMPT', payload: data });
                    }
                    setTitle(prompt.program_name);
                }).catch((error) => {
                    console.error('Error:', error);
                });
            }

            return prompt;
        });
    }, [dispatch, prompts, setTitle]);

    const inputRef = React.useRef(null);

    const handleTitleClick = () => {
        setIsEditing(true);
    };

    const handleInputBlur = () => {
        setIsEditing(false);
    };

    const handleInputChange = (e) => {
        setTitle(e.target.value);
    };

    React.useEffect(() => {
        if (isEditing && inputRef.current) {
            inputRef.current.focus();
        }
        
        openPrompt(prompt_id);
    }, [openPrompt, prompt_id, isEditing]);


    return (
        <AppLayout>
                <div className="flex items-center justify-between">
                    <div className="flex items-center w-2/3">
                            {isEditing ? (
                                <input
                                    ref={inputRef}
                                    type="text"
                                    value={title}
                                    onChange={title !== 'No program opened' ? handleInputChange : null}
                                    onBlur={title !== 'No program opened' ? handleInputBlur : null}
                                    className="text-2xl w-full"
                                    disabled={title === 'No program opened'}
                                />
                            ) : (
                                <h2 className="text-2xl" onClick={handleTitleClick}>{title}</h2>
                            )}
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <nav>
                                    <ul style={{ listStyleType: 'none', display: 'flex', gap: '10px' }}>
                                            <li>
                                                <small>Status: {opened_prompt.program_status ?? "Failed!"}</small>
                                            </li>
                                            {opened_prompt.program_name !== 'No program opened' && (<>
                                            <li><button className="px-4 py-2 rounded text-white bg-everglade-400 hover:bg-opacity-50" onClick={handleDialogOpen}>
                                                    <FaEye />
                                            </button></li>
                                            <li><button className="px-4 py-2 rounded text-white bg-everglade-400 hover:bg-opacity-50"
                                                        onClick={() => {
                                                            window.confirm('Are you sure you want to delete this program?') &&
                                                            fetch(`${BASE_URL}/program/${opened_prompt.prompt_id}`, {
                                                                method: 'DELETE',
                                                                headers: {
                                                                    'Content-Type': 'application/json',
                                                                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                                                                },
                                                            }).then((res) => {
                                                                if (res.status === 401) {
                                                                    localStorage.removeItem('token');
                                                                    localStorage.removeItem('email');
                                                                    window.location.href = '/login';
                                                                }
                                                                return res.json();
                                                            }).then((data) => {
                                                                if (data.error) {
                                                                    alert(data.error);
                                                                } else {
                                                                    window.location.href = '/code';
                                                                }
                                                            }).catch((error) => {
                                                                console.error('Error:', error);
                                                            });
                                                        }}>
                                                    <FaTrash />
                                            </button></li>
                                            </>)}
                                    </ul>
                            </nav>
                    </div>
                </div>
                <div className="mt-4">
                    <iframe style={
                        {
                            width: '100%',
                            height: '75vh',
                            border: '0',
                            borderRadius: '4px',
                            overflow: 'hidden'
                        }
                    } srcDoc={iframe_srcdoc} id="playground" title="Playground" />
                </div>
        </AppLayout>
    )
}

export default Code;    