import React, { useState } from 'react';
import { BASE_URL } from "../config";

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    

    const handleSubmit = (e) => {
        e.preventDefault();

        // send email and password to the server http://localhost:5000/login
        fetch(`${BASE_URL}/login`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email, password }),
        })
            .then((res) => {
                if (res.status === 401) {
                    localStorage.removeItem('token');
                    localStorage.removeItem('email');
                    window.location.href = '/login';
                }
                return res.json()
            }).then((data) => {
                if (data.error) {
                    alert(data.error);
                } else {
                    localStorage.setItem('token', data.token);
                    localStorage.setItem('email', email);
                    window.location.href = '/';
                }
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    };

    return (
        <div className="flex flex-col items-center justify-center h-screen bg-everglade-100">
            <h2 className="text-2xl mb-4">Login</h2>
            <form onSubmit={handleSubmit} className="flex flex-col w-80 p-5 border border-gray-300 rounded bg-white">
                <div className="mb-4">
                    <label htmlFor="email" className="block mb-2">Email:</label>
                    <input
                        type="email"
                        id="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="w-full p-2 mt-1 border border-gray-300 rounded"
                        required
                    />
                </div>
                <div className="mb-4">
                    <label htmlFor="password" className="block mb-2">Password:</label>
                    <input
                        type="password"
                        id="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        className="w-full p-2 mt-1 border border-gray-300 rounded"
                        required
                    />
                </div>
                <button type="submit" className="p-2 rounded border-none bg-everglade-500 text-white cursor-pointer">Login</button>
                <a href="/register" className="text-blue-500 mt-2">Don't have an account? Register</a>
            </form>
        </div>
    );
};

export default Login;
