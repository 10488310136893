import React from 'react';
import hero from '../images/hero.png';
import Logo from '../components/Logo';

const Home = () => {
    // get the local storage token
    const token = localStorage.getItem('token');
    if (token) {
        window.location.href = '/code';
    }

    return (
        <div className="flex flex-col min-h-screen bg-white">
            <div className="flex flex-1">
                <div className="flex flex-col items-center justify-center w-1/3 bg-white">
                    <h1 className="text-4xl font-bold text-gray-900 text-center"><Logo /></h1>
                    <p className="mt-4 text-lg text-gray-700">Programming for everyone</p>
                    <div className="mt-4 p-4 bg-everglade-100 text-center rounded shadow-lg">
                        <h2 className="text-xl font-semibold text-gray-900">Sasha brings programming to everyone</h2>
                        <p className="mt-2 text-everglade-600">
                            Think of Sasha as your personal Software Engineer. It writes
                            small programs for you to solve everyday problems while you
                            can focus on building the next big thing. 
                        </p>
                    </div>

                    <div className="mt-4 flex space-x-4">
                        <a href="/login" className="px-4 py-2 text-white bg-everglade-500 rounded hover:bg-everglade-700">
                            Login
                        </a>
                        <a href="/register" className="px-4 py-2 text-white bg-everglade-500 rounded hover:bg-everglade-700">
                            Register
                        </a>
                    </div>
                    
                </div>
                <div className="flex flex-col items-center justify-center w-2/3 bg-white">
                    <div className="mt-6 w-full px-4">
                        <img src={hero} alt="Hero" className="object-cover mx-auto" />
                    </div>
                </div>
            </div>
            <footer className="w-full py-4 text-center text-gray-700">
                &copy; {new Date().getFullYear()} <a href="https://eval.blog">Vikrant Singh Chauhan</a>. All rights reserved.
            </footer>
        </div>
    );
};

export default Home;